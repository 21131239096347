* {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

a{
    color: #000;
    text-decoration: none;
}

#header{
    width: 100%;
    height: 115px;
    background: #eee;
    background-image: url("kopf_3000.jpg");
    background-repeat: no-repeat;
    background-position: center;
}

#map-wrapper{
    width: 100%;
}

#overlay{
    position: absolute;
    z-index: 999;
    width: 100%;
    background: rgba(0,0,0,0.3);
}

#overlay .overlay-body{
    width: 50%;
    max-height: 600px;
    margin: 7% 25%;
    background: #fff;

    overflow: scroll;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02);
    border-top: 16px solid #cfbc53;
}

#overlay .overlay-header{
    text-align: right;
    cursor: pointer;
    padding: 8px 16px;
    background: #ceced0;
}

#overlay .overlay-content{
    padding: 16px 16px 16px 16px;
}

#overlay .overlay-content h1{
    font-size: 150%;
}

.leaflet-control.leaflet-searchbox-control-wrapper{

}

.leaflet-searchbox-control{

}

.leaflet-container a{
    color: #000 !important;
    text-decoration: none;
}

.leaflet-searchbox-panel-header-container i {
    display: none;
}

.leaflet-searchbox-panel-header-title{
    font-weight: normal !important;
    font-size: 18px !important;
    text-align: left !important;
    line-height: 32px !important;
    color: #000 !important;
    padding-left: 16px !important;
}

.leaflet-searchbox-panel-content{
    border-bottom: 1px solid #ddd;
}

.leaflet-searchbox-panel-list{
    color: #000 !important;
}

.leaflet-searchbox-panel-list-item div{
    padding-left: 16px;
}

.leaflet-searchbox-panel-list-item-icon {
    display: none !important;
}

.leaflet-searchbox-panel-list-item button{
    color: #000 !important;
    cursor: pointer !important;
}

.page-section{
    display: none;
}

.page-section.content-page{
    padding: 16px;
}

.page-section-header{
    width: 70%;
    margin: 0 15%;
    padding: 16px 0px;
}

.page-section-header span{
    cursor: pointer;
}

.page-section-content{
    width: 70%;
    margin: 0 15%;
    padding: 16px;
}

.page-section-content h1{
    font-size: 150%;
    margin-bottom: 16px;
}

.page-section .page-section-content div.grid-50{
    float: left;
    width: 50%;
}

.page-section .page-section-content div.grid-100{
    float: left;
    width: 100%;
    margin: 8px 0;
}

.page-section .page-section-content figure{
    text-align: center;
}

.page-section .page-section-content img{
    max-width: 600px;
}

/* === Map Popup === */

.leaflet-popup{
    min-width: 340px;
}

.leaflet-popup-content h3{
    font-size: 120%;
    font-weight: bold;
}

.leaflet-popup-content table{
    width: 100%;
}

.leaflet-popup-content tr{
    vertical-align: super;
}

.leaflet-popup-content td{
    width: 55%;
    padding-bottom: 8px;
}

.leaflet-popup-content td:first-child{
    width: 45%;
}

#popup-description{
    display: none;
}

.popup-button{
    border: 1px solid #2f2f2f;
    border-radius: 5px;
    padding: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02);
}

